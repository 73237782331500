import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import Author from "../components/Author";
import Layout from "../components/Layout";
import PostMeta from "../components/PostMeta";
import PostTags from "../components/PostTags";
import SEO from "../components/SEO";
import styles from "../styles/Post.module.scss";

class Post extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const {
      frontmatter: { title, tags, cover_image: coverImage },
    } = post;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={title} description={post.excerpt} />

        <div className={styles.postTitle}>
          <h1 className={styles.postTitle__text}>{title}</h1>

          <PostMeta post={post} />
        </div>

        <div className={[styles.post, "content-box"].join(" ")}>
          {coverImage && (
            <div className={styles.post__header}>
              <Image
                alt="Cover image"
                fixed={coverImage.childImageSharp.fixed}
                style={{ width: "100%" }}
              />
            </div>
          )}

          <div
            className={styles.post__content}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          {tags && (
            <div className={styles.post__footer}>
              <PostTags tags={tags} />
            </div>
          )}
        </div>

        <div className={styles.postComments}>
          {/* Add comment widgets here */}
        </div>

        <Author className={styles.postAuthor} />
      </Layout>
    );
  }
}

export default Post;

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        tags
        cover_image {
          childImageSharp {
            fixed(width: 860, height: 420) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
